<template>
    <div id="app" style="background-color: #171726;min-height: 100vh;">
        <div style="background-color:#171726 ;height: 130px;">
            <div>
                <van-nav-bar style="background-color: transparent;">
                    <template #title>
                        <span style="color: #ffffff;">赠送记录</span>
                    </template>
                    <template #left>
                        <van-image class="icon" fit="contain" src="img/backw.png" @click="closePage" />
                    </template>
                </van-nav-bar>

            </div>
            <div class="tabs">
                <div :class="type == 2 ? 'tab act' : 'tab'" @click="clickTab(2)">
                    <span>收到红包</span>
                    <div class="sub" v-if="type == 2"></div>
                </div>
                <div :class="type == 1 ? 'tab act' : 'tab'" @click="clickTab(1)">
                    <span>发出红包</span>
                    <div class="sub" v-if="type == 1"></div>
                </div>
            </div>
        </div>
        <div class="packet-list" id="content">
            <div v-if="list.length == 0" class="noData">
                <img src="img/noData.png" alt="">
            </div>
            <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList" v-if="list.length > 0">
                <div class="packet-item" v-for="(item, index) in list" :key="index">
                    <div style="display:flex;align-items:center">
                        <div class="left">
                            <img class="avatar" :src="item.head_portrait" alt="">
                        </div>
                        <div class="mid">
                            <div style="font-size:16px;font-weight:700;color: #ffffff;">{{ item.nickname }}</div>
                            <div style="color:#999999">ID:{{ item.uid }}</div>
                            <div style="color:#999999">{{ item.created_at }}</div>
                        </div>
                    </div>

                    <div class="right">
                        <div style="padding:5px 0 5px 0;text-align: right"><span
                                style="font-size:22px;font-weight:700;color: #ffffff;">{{
                                    item.diamonds
                                }}</span><img class="gold" src="img/bi.png" alt=""></div>
                        <div style="color:#ffc639;text-align: right;">金币余额:{{ item.receive_before_diamonds }}</div>
                    </div>
                </div>
            </van-list>
            <!-- </van-pull-refresh> -->
        </div>
    </div>
</template>
<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { sendRecord } from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
    name: 'packetList',
    components: {
        NavBar
    },
    data() {
        return {
            type: 2,
            list: [],
            page: 1,
            // refreshing: false,
            loading: false,
            finished: false
        }
    },
    methods: {
        getList() {
            let param = {
                type: this.type,
                page: this.page
            }
            sendRecord(param).then(res => {
                this.loading = false
                console.log(res.data)
                if (this.page == 1) {
                    this.list = res.data
                } else {
                    this.list = this.list.concat(res.data);
                }
                this.page = this.page + 1
                if (res.data.length < 15) {
                    this.finished = true;
                }
            })
        },
        clickTab(type) {
            this.type = type
            this.page = 1
            this.list = []
            this.finished = false
            this.getList()
        },
        closePage() {
            let params = '调用'
            this.$bridge.callhandler('closePage', params, (data) => {
                // 处理返回数据
            })
        },

    },

    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getList()
    }
}
</script>
<style>
/* html,body{
    height: 100%;
    overflow: scroll;
    background-color: #171726;
} */
.tabs {
    width: 480px;
    height: 60px;
    display: flex;
    margin: 40px auto 0;
    justify-content: space-between;
    font-size: 28px;
}

.tab {
    width: 150px;
    color: #999999;
    text-align: center;
}

.sub {
    width: 40px;
    height: 10px;
    background-color: #ffffff;
    margin: 10px auto 0;
    border-radius: 3px;
}

.act {
    color: #ffffff;
}

.packet-list {
    width: 700px;
    height: auto;
    margin: -40px auto 0;
}

.packet-item {
    width: 670px;
    height: 190px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
}

.avatar {
    width: 96px;
    height: 96px;
    border-radius: 50%;
}

.gold {
    width: 32px;
    height: 32px;
}

.mid div {
    padding: 5px 0 5px 0;
}

.left {
    margin-left: 30px;
}

.mid {
    margin-left: 24px;
}

.no-data {
    text-align: center;
    margin-top: 200px;
    color: #ffffff;
}

.van-nav-bar::after {
    border: none;
}
</style>
<style scoped>
.icon {
    width: 20px;
    height: 35px;
}
</style>